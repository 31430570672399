<template>
    <custom-table
        :title="$t('pages.ecommerce.invoiceManagement.provider.title')"
        :subTitle="$t('pages.ecommerce.invoiceManagement.provider.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.ecommerce.invoiceManagement.provider.paymentMethod.title')" placement="top">
                    <router-link :to="'/ecommerce/invoice-management/provider/payment-method/' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/arrows/arr075.svg"/>
                        </span>
                    </router-link>
                </el-tooltip>

                <el-tooltip :content="$t('pages.ecommerce.invoiceManagement.provider.product.title')" placement="top">
                    <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" v-on:click="redirectProductRelationPage(record.id)">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/ecommerce/ecm009.svg"/>
                        </span>
                    </a>
                </el-tooltip>

                <a v-on:click="fetchProvider(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                         <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>

                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_provider" ref="addProviderModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="providerForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.invoiceManagement.provider.cols.code') }}</label>
                                <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.code" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.invoiceManagement.provider.cols.name') }}</label>
                                <el-form-item prop="name" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.name" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.invoiceManagement.provider.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <template v-if="form.credentials_layout.length">
                                <div class="mb-10"></div>
                                <el-divider content-position="center">{{ $t('pages.ecommerce.invoiceManagement.provider.credential.title') }}</el-divider>

                                <div class="fv-row mb-7" v-for="(credential, credentialIndex) in form.credentials_layout" :key="credentialIndex">
                                    <label class="fs-6 fw-bold mb-2">{{ credential.title }}</label>
                                    <el-form-item :prop="'credentials.' + credential.opt">
                                        <el-input v-model="form.data.credentials[credential.opt]" type="text" v-if="credential.type == 'input'"/>
                                    </el-form-item>
                                </div>
                            </template>
                            <template v-if="form.settings_layout.length && 0">
                                <div class="mb-10"></div>
                                <el-divider content-position="center">{{ $t('pages.ecommerce.invoiceManagement.provider.setting.title') }}</el-divider>

                                <div class="fv-row mb-7" v-for="(setting, settingIndex) in form.settings_layout" :key="settingIndex">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.invoiceManagement.provider.setting.' + setting.opt) }}</label>
                                    <el-form-item :prop="'settings.' + setting.opt">
                                        <el-input v-model="form.data.settings[setting.opt]" type="text" v-if="setting.type == 'input'"/>
                                        <el-radio-group v-model="form.data.settings[setting.opt]" v-if="setting.type == 'radio'">
                                            <el-radio-button label="1">{{ $t("common.yes") }}</el-radio-button>
                                            <el-radio-button label="0">{{ $t("common.no") }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.provider.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.provider.cols.code"),
                    key: "code"
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.provider.cols.name"),
                    key: "name"
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.provider.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                credentials_layout: [],
                settings_layout: [],
                data: {
                    credentials: {},
                    settings: {}
                }
            },
            responseMessages: {
                provider_handler_not_found_by_code: "providerHandlerNotFoundByCode",
                must_have_at_least_one_active_provider: "mustHaveLeastOneActiveProvider"
            },
            selectedRowKeys: []
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.invoiceManagement.provider.table;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.invoiceProviders"), [this.$t("menu.invoiceManagement")]);
        this.$store.dispatch('ecommerce/invoiceManagement/provider/get', {page: {}});
    },
    methods: {
        newProvider() {
            this.form.updateStatus = false;
            this.form.data = {
                credentials: {},
                settings: {},
                active: true
            };
            this.form.title = this.$t("pages.ecommerce.invoiceManagement.provider.newProvider");
            this.showModal(this.$refs.addProviderModal);
        },
        fetchProvider(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.invoiceManagement.provider.editProvider");
            this.axios.get(this.endpoints['ecommerce_invoice_provider'] + '/' + record.id).then(response => {
                let responseData = response.data.data;

                ['credentials', 'settings'].forEach((key) => {
                    let data = responseData[key];

                    let layoutKey = this.sprintf('%s_layout', [key]);
                    this.form[layoutKey] = responseData[layoutKey];

                    responseData[key] = {};

                    let layout = responseData[layoutKey];
                    layout.forEach((layoutData) => {
                        if (typeof (layoutData.default) !== 'undefined') {
                            responseData[key][layoutData.opt] = layoutData.default;
                        }
                    })

                    if (data.length > 0) {
                        data.forEach((item) => {
                            responseData[key][item.opt] = item.val;
                        })
                    }
                });

                this.form.data = responseData;
                this.showModal(this.$refs.addProviderModal);
            });
        },
        onSubmit() {
            this.$refs.providerForm.validate((valid) => {
                if (valid) {
                    if (this.preparePaymentFormData()) {
                        this.form.loading = true;

                        if (this.form.data.id) {
                            this.axios.put(this.endpoints['ecommerce_invoice_provider'] + '/' + this.form.data.id, this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addProviderModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                if (this.responseMessages[error.response.data.message]) {
                                    error.response.data.message = this.$t('pages.ecommerce.invoiceManagement.provider.responseMessages.' + this.responseMessages[error.response.data.message]);
                                }

                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        } else {
                            this.axios.post(this.endpoints['ecommerce_invoice_provider'], this.form.data).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addProviderModal);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                if (this.responseMessages[error.response.data.message]) {
                                    error.response.data.message = this.$t('pages.ecommerce.invoiceManagement.provider.responseMessages.' + this.responseMessages[error.response.data.message]);
                                }

                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/invoiceManagement/provider/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        preparePaymentFormData() {
            let formData = this.form.data;

            ['credentials', 'settings'].forEach((key) => {
                let data = {};
                for (let index in formData[key]) {
                    if (formData[key][index].trim().length) {
                        data[index] = formData[key][index];
                    }
                }

                formData[key] = data;
            });


            return true;
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newProvider();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/invoiceManagement/provider/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/invoiceManagement/provider/get", {
                page: pagination,
                filterData: filterData
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        async redirectProductRelationPage(id) {
            let supportResult = await this.isProviderSupport(id, 'ProductList');
            if(!supportResult.status) {
                this.$notify({
                    type: 'error',
                    title: this.$t("messages.error"),
                    message: this.$t('pages.ecommerce.invoiceManagement.provider.responseMessages.supportError'),
                });

                return;
            }

            await this.$router.push({
                path: "/ecommerce/invoice-management/provider/product/" + id
            });
        }
    }
}
</script>

<style scoped>

</style>